import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import { Auth0Provider } from './react-auth0-spa';
import history from './util/history';
import './index.scss';

const { NODE_ENV: environment } = process.env;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const DOMAIN = process.env.REACT_APP_AUTHO_DOMAIN;
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const BASE_URL = process.env.REACT_APP_BASE_URL;

if (environment === 'production') {
  Sentry.init({
    dsn: 'https://e9a768d4d3f941de9912f35835f4b75d@sentry.io/1486361',
    environment,
  });
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={DOMAIN}
    client_id={CLIENT_ID}
    redirect_uri={BASE_URL}
    audience={AUDIENCE}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);
