import React from 'react';

import AvailableTimeOnDay from './AvailableTimeOnDay';

export default function AvailableTimesOnDay({
  day,
  availability,
  addAvailability,
  removeAvailability,
  updateAvailability,
}) {
  const availableTimesOnDay = availability.map((availableTime, idx) => {
    return (
      <AvailableTimeOnDay
        day={day}
        start={availableTime.start}
        end={availableTime.end}
        addAvailability={addAvailability}
        removeAvailability={removeAvailability}
        updateAvailability={updateAvailability}
        idx={idx}
        first={idx === 0}
        last={idx === availability.length - 1}
      />
    );
  });

  return <div>{availableTimesOnDay}</div>;
}
