import React from 'react';

import styles from './Skeleton.module.scss';

export default function Skeleton({ header }) {
  return (
    <>
      <div className={styles.header}>{header}</div>
      <div className={styles.subheader}>Coming soon...</div>
      <div className={styles.smallSection}>
        <div className={styles.smallLine} />
        <div className={styles.smallLine2} />
      </div>
      <div className={styles.largeSection}>
        <div className={styles.largeLine} />
        <div className={styles.largeLine} />
        <div className={styles.largeLineHalf} />
      </div>
      <div className={styles.largeSection}>
        <div className={styles.mediumBlock} />
        <div className={styles.largeLine} />
        <div className={styles.largeLine} />
        <div className={styles.largeLineHalf} />
      </div>
      <div className={styles.largeSection}>
        <div className={styles.mediumBlock} />
        <div className={styles.largeLine} />
        <div className={styles.largeLine} />
        <div className={styles.largeLineHalf} />
      </div>
    </>
  );
}
