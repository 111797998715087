import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Button from 'pages/Profile/Button';
import NotFound from 'components/NotFound';
import poweredByProfound from 'assets/poweredByProfound.svg';
import banner from 'assets/banner.png';
import { buildCompanyAndTitle } from 'util/user';
import { step, stepMap } from './steps.js';
import { withBookingContext, withBookingProvider } from './BookingContext';
import styles from './Profile.module.scss';

const Profile = ({ user, updateUser, match, currentStep, nextStep }) => {
  const [timezone, setTimezone] = useState();

  useEffect(() => {
    const getUserEffect = async () => {
      const { username } = match.params;
      await updateUser(username);
    };

    getUserEffect();
  }, []);

  if (!user) return <NotFound />;

  const { company, title, hourlyRate, profilePicture, displayName } = user;

  // TODO: Add banner images into the backend and allow users to change images
  const bannerStyles = {
    background: `url(${banner}) no-repeat center center / cover`,
  };

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileCard}>
        <div className={styles.banner} style={bannerStyles} />
        <img className={styles.poweredByProfound} src={poweredByProfound} />
        <div className={styles.profilePhotoContainer}>
          <img className={styles.profilePhoto} src={profilePicture} />
        </div>
        <div className={styles.nameAndButtonContainer}>
          <div className={styles.profileNameAndTitleContainer}>
            <h1>{displayName}</h1>
            <div className={styles.highlightContainer}>
              <h2>{buildCompanyAndTitle(title, company)}</h2>
              <div className={styles.highlightYellow} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {currentStep === step.ABOUT ? (
              <Button onClick={() => nextStep()} maxWidth>
                <div className={styles.buttonContentContainer}>
                  <p>
                    {' '}
                    Talk to me for <strong>${hourlyRate / 2}</strong>
                  </p>
                </div>
              </Button>
            ) : (
              <div className={styles.pricing}>
                <div>
                  <span className={styles.price}>${hourlyRate / 2}</span> per 30 min
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.seperator} />
        {stepMap[currentStep]()}
      </div>
    </div>
  );
};

export default withBookingProvider(withBookingContext(Profile));
