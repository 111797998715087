import React from 'react';

import './LoadingAnimation.scss';

const LoadingAnimation = () => {
  return (
    <div className='animation-center-viewport'>
      <div className='lds-ring'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingAnimation;
