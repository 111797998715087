import React from 'react';

import styles from '../WeeklyAvailabilitiesInput.module.scss';

export default function Day({ day: { day, initial }, chosenDays, onDayClick }) {
  const className = `${styles.dayBadge} ${chosenDays.includes(day) ? styles.dayBadgeChosen : ''}`;
  return (
    <button type="button" className={className} onClick={onDayClick} value={day}>
      {initial}
    </button>
  );
}
