export const days = [
  {
    day: 'Sun',
    initial: 'S',
    number: 0,
  },
  {
    day: 'Mon',
    initial: 'M',
    number: 1,
  },
  {
    day: 'Tue',
    initial: 'T',
    number: 2,
  },
  {
    day: 'Wed',
    initial: 'W',
    number: 3,
  },
  {
    day: 'Thu',
    initial: 'T',
    number: 4,
  },
  {
    day: 'Fri',
    initial: 'F',
    number: 5,
  },
  {
    day: 'Sat',
    initial: 'S',
    number: 6,
  },
];

export function chosenDaysSorter(day1, day2) {
  const { number: day1Number } = days.find(({ day }) => day === day1);
  const { number: day2Number } = days.find(({ day }) => day === day2);
  // eslint-disable-next-line no-nested-ternary
  return day1Number < day2Number ? -1 : day1Number > day2Number ? 1 : 0;
}

export function generateTimes() {
  const times = [];
  const timeIntervalInMins = 30;
  let startTimeInMins = 0;
  const ap = ['am', 'pm'];

  while (startTimeInMins < 24 * 60) {
    const hh = Math.floor(startTimeInMins / 60);
    const hhLabel = hh % 12 ? `0${hh % 12}`.slice(-2) : '12';

    const mm = startTimeInMins % 60;
    const mmLabel = `0${mm}`.slice(-2);

    const apLabel = ap[Math.floor(hh / 12)];

    const time = `${hhLabel}:${mmLabel} ${apLabel}`;
    times.push(time);

    startTimeInMins += timeIntervalInMins;
  }
  times.push('12:00 am');

  return times;
}
