import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_ROUTE;

/**
 * Get the user from the db if the user exist or create the user
 * @param {Object} userInfo
 * @returns {Promise}
 */
export async function getOrPostUser(userInfo, idToken) {
  const getUsersResponse = await axios.get(`${BASE_URL}/users?auth0Id=${userInfo.auth0Id}`);
  let user = {};

  if (getUsersResponse.data.length !== 0) {
    // if a user with the auth0Id already exists
    user = getUsersResponse.data[0];
  } else {
    // this is a new user

    const createUserResponse = await axios.post(`${BASE_URL}/users`, userInfo, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    user = createUserResponse.data;
  }

  return user;
}

/**
 * Update the user with the given userId with the userInfo
 *
 * @param {String} userId
 * @param {Object} userInfo
 *
 * @returns {Promise}
 */

export async function updateUser(userId, userInfo, idToken) {
  try {
    const user = await axios.put(`${BASE_URL}/users/${userId}`, userInfo, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return { data: user.data, err: null };
  } catch (err) {
    return {
      data: undefined,
      err: {
        status: err.response.status,
        description: err.response.data,
      },
    };
  }
}

/**
 * Retrieve a user given query params i.e. { username: 'jeremy' }
 *
 * @param {Object} params
 *
 * @returns {Promise}
 */
export async function getUsers(params) {
  const response = await axios.get(`${BASE_URL}/users`, { params });

  return response.data;
}

/**
 * Retrieve the expertise of a user given the userId
 *
 * @param {String} userId
 *
 * @returns {Promise}
 */
export async function getExpertise(userId) {
  const response = await axios.get(`${BASE_URL}/users/${userId}/expertise`);

  return response.data;
}
