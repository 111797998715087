import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_ROUTE;

export async function postImage(images) {
  const formData = new FormData();

  images.forEach((image, i) => {
    formData.append(i, image);
  });

  console.log(formData);

  const res = await axios({
    method: 'post',
    url: `${BACKEND_URL}/image`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return res;
}
