import React from 'react';

import { withBookingContext } from 'pages/Profile/BookingContext';
import { buildName } from 'util/user';
import congrats from 'assets/congrats.svg';
import styles from './Completion.module.scss';

const Completion = ({ user }) => (
  <div className={styles.container}>
    <div className={styles.text}>
      Congrats! You’ve booked a session with
      {' '}
      <b>{buildName(user.firstName, user.lastName)}</b>
      .
      Check your email for more instructions and a calendar invite. Please reach out to
      {' '}
      <a href="mailto: hi@joinprofound.com">hi@joinprofound.com</a>
      {' '}
      with any additional questions.
    </div>
    <img src={congrats} className={styles.congratsImage} />
  </div>
);

export default withBookingContext(Completion);
