import React, { Component } from 'react';
import {
  Redirect, BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Provider } from 'react-redux';

import LoadingPage from 'components/LoadingPage';
import NotFound from 'components/NotFound';
import { Admin, Profile } from 'pages';
import store from './store';
import { useAuth0 } from './react-auth0-spa';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export default function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <LoadingPage />;
  }

  const stripePromise = loadStripe(STRIPE_KEY);
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/u/:username" component={Profile} />
            <Route exact path="/">
              <Redirect to="/admin" />
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </Elements>
    </Provider>
  );
}
