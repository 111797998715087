import React from 'react';

import { useAuth0 } from 'react-auth0-spa';
import Input from 'components/Input';
import { updateUser } from 'services/user';
import dollar from 'assets/dollar.svg';
import link from 'assets/link.svg';
import ImageInput from './ImageInput';
import WeeklyAvailabilitiesInput from './WeeklyAvailabilitiesInput';
import styles from './User.module.scss';

export default function User() {
  const { user, getTokenSilently, setUser } = useAuth0();

  const updateValue = async userObject => {
    const token = await getTokenSilently();
    const res = await updateUser(user.id, userObject, token);

    if (res.data) {
      setUser(res.data);
    }

    return res;
  };

  return (
    <>
      <div className={styles.inputSection}>
        <div className={styles.header}>Basic Information</div>
        <Input
          label="Username"
          type="text"
          field="username"
          initialValue={user.username}
          updateValue={updateValue}
        />
        <Input
          label="First Name"
          type="text"
          field="firstName"
          initialValue={user.firstName}
          updateValue={updateValue}
        />
        <Input
          label="Last Name"
          type="text"
          field="lastName"
          initialValue={user.lastName}
          updateValue={updateValue}
        />
        <Input
          label="Display Name"
          type="text"
          field="displayName"
          initialValue={user.displayName}
          updateValue={updateValue}
        />
        <Input
          label="Title"
          type="text"
          initialValue={user.title}
          field="title"
          updateValue={updateValue}
        />
        <Input label="Email" type="text" initialValue={user.email} disabled />
        <ImageInput
          profilePicture={user.profilePicture}
          field="profilePicture"
          updateValue={updateValue}
        />
        <Input
          label="Bio"
          type="textarea"
          initialValue={user.bio}
          field="bio"
          updateValue={updateValue}
        />
      </div>
      <div className={styles.inputSection}>
        <div className={styles.header}>Service Rate</div>
        <Input
          label="Hourly Rate"
          type="number"
          initialValue={user.hourlyRate}
          field="hourlyRate"
          updateValue={updateValue}
          step="0.01"
          icon={dollar}
        />
      </div>
      <div className={styles.inputSection}>
        <div className={styles.header}>Weekly Availabilities</div>
        <WeeklyAvailabilitiesInput />
      </div>
      <div className={styles.inputSection}>
        <div className={styles.header}>Social Profiles</div>
        <Input
          label="Linkedin"
          type="text"
          initialValue={user.linkedin}
          field="linkedin"
          updateValue={updateValue}
          icon={link}
        />
        <Input
          label="Twitter"
          type="text"
          initialValue={user.twitter}
          field="instagram"
          updateValue={updateValue}
          icon={link}
        />
        <Input
          label="Instagram"
          type="text"
          initialValue={user.instagram}
          field="instagram"
          updateValue={updateValue}
          icon={link}
        />
      </div>
    </>
  );
}
