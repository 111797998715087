import React from 'react';

import styles from './Button.module.scss';

export default function Button({
  children, onClick, disabled, maxWidth = false,
}) {
  return (
    <button
      className={[styles.button, maxWidth ? styles.maxWidth : ''].join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
