import React from 'react';

import linkedinIcon from 'assets/linkedin.svg';
import twitterIcon from 'assets/twitter.svg';
import instagramIcon from 'assets/instagram.svg';

import styles from './Icons.module.scss';

const iconFactory = (companyName, href, iconSvg) => {
  if (!href) return <div />;

  const altText = `${companyName} logo`;
  const absoluteHref = href.search('http') === -1 ? `https://${href}` : href;

  return (
    <a href={absoluteHref} target="_blank" className={styles['booking-icon']}>
      <img src={iconSvg} alt={altText} />
    </a>
  );
};

const LinkedInIcon = (props) => iconFactory('LinkedIn', props.pandit.linkedin, linkedinIcon);
const TwitterIcon = (props) => iconFactory('Twitter', props.pandit.twitter, twitterIcon);
const InstagramIcon = (props) => iconFactory('Instagram', props.pandit.instagram, instagramIcon);

const Icons = (props) => (
  <>
    <LinkedInIcon {...props} />
    <TwitterIcon {...props} />
    <InstagramIcon {...props} />
  </>
);

export default Icons;
