import About from 'pages/Profile/About';
import BookingDetails from 'pages/Profile/BookingDetails';
import Completion from 'pages/Profile/Completion';
import ConfirmationAndPayments from 'pages/Profile/ConfirmationAndPayments';
import SelectTimeAndDate from 'pages/Profile/SelectTimeAndDate';

export const step = {
  ABOUT: 'ABOUT',
  SELECT_DATE_AND_TIME: 'SELECT_DATE_AND_TIME',
  BOOKING_DETAILS: 'BOOKING_DETAILS',
  CONFIRMATION_AND_PAYMENTS: 'CONFIRMATION_AND_PAYMENTS',
  COMPLETION: 'COMPLETION',
};

export const stepMap = {
  [step.ABOUT]: About,
  [step.SELECT_DATE_AND_TIME]: SelectTimeAndDate,
  [step.BOOKING_DETAILS]: BookingDetails,
  [step.CONFIRMATION_AND_PAYMENTS]: ConfirmationAndPayments,
  [step.COMPLETION]: Completion,
};

export const nextStepMap = {
  [step.ABOUT]: step.SELECT_DATE_AND_TIME,
  [step.SELECT_DATE_AND_TIME]: step.BOOKING_DETAILS,
  [step.BOOKING_DETAILS]: step.CONFIRMATION_AND_PAYMENTS,
  [step.CONFIRMATION_AND_PAYMENTS]: step.COMPLETION,
};
