import { createStore } from 'redux';

import _ from 'lodash';

const initialState = {
  availability: {
    Sunday: { name: 'Sunday', bookings: [] },
    Monday: { name: 'Monday', bookings: [] },
    Tuesday: { name: 'Tuesday', bookings: [] },
    Wednesday: { name: 'Wednesday', bookings: [] },
    Thursday: { name: 'Thursday', bookings: [] },
    Friday: { name: 'Friday', bookings: [] },
    Saturday: { name: 'Saturday', bookings: [] },
  },
};

function todos(state = [], action) {
  switch (action.type) {
    case 'UpdateAvailability':
      const updatedDay = state.availability[action.dayName];
      const bookings = _.concat([], updatedDay.bookings, action.booking);

      return {
        ...state,
        availability: {
          ...state.availability,
          [action.dayName]: {
            ...updatedDay,
            bookings,
          },
        },
      };
    default:
      return state;
  }
}

const store = createStore(todos, initialState);

export default store;
