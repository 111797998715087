import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Ring } from 'react-spinners-css';

import variables from 'sass/variables.scss';
import { postImage } from 'services/image';
import styles from './ImageInput.module.scss';

export default function ImageInput({ profilePicture, updateValue }) {
  const [loading, setLoading] = useState(false);

  const onChangeHandler = async (e) => {
    setLoading(true);
    const response = await postImage([e.target.files[0]]);
    updateValue({ profilePicture: response.data[0].secure_url });
    setLoading(false);
  };

  return (
    <label>
      Avatar
      <div className={styles.container}>
        <img className={styles.avatar} src={profilePicture} />
        <label htmlFor="file" className={styles.uploadButton}>
          {loading ? (
            <Ring className={styles.loading} color={variables.colorBackground} size={23} />
          ) : (
            'Upload Avatar'
          )}
        </label>
        <input
          className={styles.fileInput}
          type="file"
          id="file"
          name="file"
          onChange={onChangeHandler}
        />
      </div>
    </label>
  );
}
