import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import variables from 'sass/variables.scss';

import { generateTimes } from '../../utils';
import styles from '../../WeeklyAvailabilitiesInput.module.scss';

const dropdownStyles = {
  container: styles => ({
    ...styles,
    width: '136px',
    outline: 'none',
  }),
  control: styles => ({
    ...styles,
    width: '136px',
    backgroundColor: '#D2CEFA',
    border: 'none',
    borderRadius: '5px',
    padding: '4px',
    outline: 'none',
    cursor: 'pointer',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: variables.colorBlack,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
};

export default function AvailableTimeOnDay({
  day,
  start = null,
  end = null,
  addAvailability,
  removeAvailability,
  updateAvailability,
  idx,
  first,
  last,
}) {
  const times = generateTimes();
  const timesOptions = times.map(time => ({
    value: time,
    label: time,
    isDisabled: false,
  }));

  let options = cloneDeep(timesOptions);
  options = timesOptions.slice(0, timesOptions.length - 1);
  const [startTimesOptions, setStartTimesOptions] = useState(options);

  options = cloneDeep(timesOptions);
  options = timesOptions.slice(1, timesOptions.length);
  const [endTimesOptions, setEndTimesOptions] = useState(options);

  const [chosenStartTime, setChosenStartTime] = useState(start);
  const [chosenEndTime, setChosenEndTime] = useState(end);

  const disableEndTimeOptions = startTime => {
    const startIdx = startTimesOptions.map(({ value }) => value).indexOf(startTime);
    const newEndTimeOptions = cloneDeep(endTimesOptions);
    for (let i = 0; i < newEndTimeOptions.length; i += 1) {
      newEndTimeOptions[i].isDisabled = i < startIdx;
    }
    setEndTimesOptions(newEndTimeOptions);
  };

  useEffect(() => {
    if (start) {
      disableEndTimeOptions(start);
    }
  }, []);

  const onStartTimeChange = async e => {
    if (chosenEndTime) {
      const getMoment = time => moment(time, 'hh:mm a');

      const startMoment = getMoment(e.value);
      const endMoment = getMoment(chosenEndTime);
      if (startMoment.isBefore(endMoment) || endMoment.format('HH:mm') === '00:00') {
        await updateAvailability(day, idx, { start: e.value });
      } else {
        await updateAvailability(day, idx, { start: e.value, end: null });
        setChosenEndTime(null);
      }
    }

    setChosenStartTime(e.value);

    disableEndTimeOptions(e.value);
  };

  const onEndTimeChange = async e => {
    await updateAvailability(day, idx, { start: chosenStartTime, end: e.value });
    setChosenEndTime(e.value);
  };

  const onDelete = async () => {
    await removeAvailability(day, idx);
  };

  const onAdd = () => {
    addAvailability(day, null, null);
  };

  // TODO(enhancement): only show the add button if both chosen start and end times have been
  // selected
  return (
    <div className={styles.dayAvailability}>
      <div className={styles.dayLabel}>{first && day}</div>
      <Select
        className="time-availability-start-dropdown"
        options={startTimesOptions}
        onChange={onStartTimeChange}
        value={{ value: chosenStartTime, label: chosenStartTime }}
        styles={dropdownStyles}
      />
      <div>to</div>
      <Select
        className="time-availability-end-dropdown"
        options={endTimesOptions}
        onChange={onEndTimeChange}
        value={{ value: chosenEndTime, label: chosenEndTime }}
        isDisabled={chosenStartTime === null}
        styles={dropdownStyles}
      />
      <div className={styles.actions}>
        <button
          className={`${styles.actionButton} ${styles.delete}`}
          type="button"
          onClick={onDelete}
        >
          Delete
        </button>
        {last && (
          <button className={`${styles.actionButton} ${styles.add}`} type="button" onClick={onAdd}>
            Add
          </button>
        )}
      </div>
    </div>
  );
}
