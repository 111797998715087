import React, { useState, useEffect, createContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { changeTimezone, getDefaultTimezone } from 'util/timezone';
import { getBookings, postBooking } from 'services/booking';
import { getUsers } from 'services/user';
import { getWeeklyAvailability } from 'services/weeklyAvailability';

import { step, nextStepMap } from 'pages/Profile/steps';
import { maskIntervals } from 'util/intervals';

const BookingContext = createContext();

function BookingProvider({ children }) {
  const [user, setUser] = useState({});
  const [currentStep, setCurrentStep] = useState(step.ABOUT);
  const [timezone, setTimezone] = useState(getDefaultTimezone());
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [weeklyAvailability, setWeeklyAvailability] = useState();
  const [bookings, setBookings] = useState();

  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientGoal, setClientGoal] = useState('');

  useEffect(() => {
    setWeeklyAvailability(changeTimezoneWrapper(weeklyAvailability));
    setBookings(changeTimezoneWrapper(bookings));
    setSelectedTimes([]);
  }, [timezone]);

  useEffect(() => {
    setSelectedTimes([]);
  }, [selectedDate]);

  useEffect(() => {
    const getWeeklyAvailabilityAndBookings = async () => {
      const avail = await getWeeklyAvailability(user.id);
      setWeeklyAvailability(changeTimezoneWrapper(avail));
      const bookings = await getBookings({ panditId: user.id });
      setBookings(changeTimezoneWrapper(bookings));
    };

    if (!_.isEmpty(user)) getWeeklyAvailabilityAndBookings();
  }, [user]);

  const changeTimezoneWrapper = times => changeTimezone(times, timezone);

  const updateUser = async username => {
    const users = await getUsers({ username: username.toLowerCase() });
    setUser(users[0]);
  };

  const nextStep = () => {
    setCurrentStep(nextStepMap[currentStep]);
  };

  const getAvailabilityByDate = date => {
    const momentDate = moment(date);

    const dateAvailability = _.filter(weeklyAvailability, x => x.day === momentDate.day());

    const dateBookings = _.filter(bookings, booking => booking.start.date() === momentDate.date());

    const intervals = _.map(dateAvailability, avail => ({
      start: avail.start.format('HH:mm:ss'),
      end: avail.end.format('HH:mm:ss'),
    }));

    const masks = _.map(dateBookings, booking => ({
      start: booking.start.format('HH:mm:ss'),
      end: booking.end.format('HH:mm:ss'),
    }));

    return maskIntervals(intervals, masks);
  };

  const createBooking = async stripeToken => {
    const costWithoutFees = parseFloat(
      Math.round((user.hourlyRate / 2) * selectedTimes.length * 100) / 100
    ).toFixed(2);
    const total = parseFloat(Math.round(costWithoutFees * 1.15 * 100) / 100).toFixed(2);

    console.log(clientGoal);

    await postBooking(
      user.id,
      {
        clientName,
        clientEmail,
        clientGoal,
        selectedDate,
        selectedTimes,
        timezone,
      },
      stripeToken,
      total
    );
  };

  return (
    <BookingContext.Provider
      value={{
        user,
        updateUser,
        currentStep,
        nextStep,
        timezone,
        setTimezone,
        selectedDate,
        setSelectedDate,
        selectedTimes,
        setSelectedTimes,
        bookings,
        weeklyAvailability,
        getAvailabilityByDate,
        clientName,
        setClientName,
        clientEmail,
        setClientEmail,
        clientGoal,
        setClientGoal,
        createBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

export function withBookingProvider(Component) {
  return props => (
    <BookingProvider>
      <Component {...props} />
    </BookingProvider>
  );
}

export function withBookingContext(Component) {
  return props => (
    <BookingContext.Consumer>
      {value => <Component {...props} {...value} />}
    </BookingContext.Consumer>
  );
}
