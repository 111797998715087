import Select from 'react-select';
import React from 'react';

import timezones from 'assets/timezones';
import { withBookingContext } from 'pages/Profile/BookingContext';
import variables from 'sass/variables.scss';
import styles from './TimezoneDropdown.module.scss';

const timezoneOptions = timezones.map(timezone => ({ value: timezone, label: timezone.text }));

const dropdownStyles = {
  container: styles => ({
    ...styles,
    width: '100%',
    outline: 'none',
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#D2CEFA',
    border: 'none',
    borderRadius: '5px',
    padding: '8px',
    outline: 'none',
    cursor: 'pointer',
  }),
  dropdownIndicator: styles => ({
    color: variables.colorBlack,
  }),
};

const TimezoneDropdown = ({ timezone, setTimezone }) => (
  <div className={styles.container}>
    <Select
      className="timezone-dropdown"
      onChange={e => setTimezone(e.value)}
      options={timezoneOptions}
      defaultValue={{ value: timezone, label: timezone.text }}
      styles={dropdownStyles}
    />
  </div>
);

export default withBookingContext(TimezoneDropdown);
