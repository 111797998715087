import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import chart from 'assets/chart.svg';
import calendar from 'assets/calendar.svg';
import card from 'assets/card.svg';
import logo from 'assets/min_logo.svg';
import profile from 'assets/profile.svg';
import { useAuth0 } from 'react-auth0-spa';
import styles from './Navbar.module.scss';

export default function Navbar() {
  const { user, logout } = useAuth0();
  const route = window.location.pathname;

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <div className={styles.container}>
      <div>
        <Link to="/admin/">
          <div className={styles.logo}>
            <img src={logo} />
          </div>
        </Link>
        <Link to="/admin/profile">
          <div
            className={[styles.navItem, route === '/admin/profile' ? styles.active : ''].join(' ')}
          >
            <svg
              width="33"
              height="30"
              viewBox="0 0 33 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.5 26.0221V23.618C27.5 22.3428 26.9205 21.1198 25.8891 20.2181C24.8576 19.3164 23.4587 18.8099 22 18.8099H11C9.54131 18.8099 8.14236 19.3164 7.11091 20.2181C6.07946 21.1198 5.5 22.3428 5.5 23.618V26.0221"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 14.0017C19.5376 14.0017 22 11.849 22 9.19356C22 6.53809 19.5376 4.38541 16.5 4.38541C13.4624 4.38541 11 6.53809 11 9.19356C11 11.849 13.4624 14.0017 16.5 14.0017Z"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
        <Link to="/admin/bookings">
          <div
            className={[styles.navItem, route === '/admin/bookings' ? styles.active : ''].join(' ')}
          >
            <svg
              width="33"
              height="30"
              viewBox="0 0 33 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.125 5.38571H6.875C5.35622 5.38571 4.125 6.46205 4.125 7.78979V24.6183C4.125 25.946 5.35622 27.0224 6.875 27.0224H26.125C27.6438 27.0224 28.875 25.946 28.875 24.6183V7.78979C28.875 6.46205 27.6438 5.38571 26.125 5.38571Z"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 2.98166V7.78981"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 2.98166V7.78981"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.125 12.598H28.875"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
        <Link to="/admin/payments">
          <div
            className={[styles.navItem, route === '/admin/payments' ? styles.active : ''].join(' ')}
          >
            <svg
              width="33"
              height="30"
              viewBox="0 0 33 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.875 5.18402H4.125C2.60622 5.18402 1.375 6.26036 1.375 7.5881V22.0125C1.375 23.3403 2.60622 24.4166 4.125 24.4166H28.875C30.3938 24.4166 31.625 23.3403 31.625 22.0125V7.5881C31.625 6.26036 30.3938 5.18402 28.875 5.18402Z"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.375 12.3963H31.625"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
        <Link to="/admin/analytics">
          <div
            className={[styles.navItem, route === '/admin/analytics' ? styles.active : ''].join(
              ' ',
            )}
          >
            <svg
              width="32"
              height="29"
              viewBox="0 0 32 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 23.4864V11.8303"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 23.4864V4.83661"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 23.4864V16.4927"
                stroke="#312F2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
      </div>
      <div className={styles.userPhoto}>
        <div className={styles.userPopover} onClick={() => logout({ returnTo: BASE_URL })}>
          Logout
        </div>
        <img src={user.profilePicture} />
      </div>
    </div>
  );
}
