import _ from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';

import timezones from '../assets/timezones.json';

const utc = timezones.find((timezone) => timezone.offset === 0);

const BASE_URL = process.env.REACT_APP_BACKEND_ROUTE;

/**
 * Merge overlapping intervals.
 */
function _mergeOverlappingTimes(times) {
  const sortedTimes = times.sort();

  let i = 0;

  while (i < sortedTimes.length - 1) {
    const splitIndex = _.split(sortedTimes[i], ' - ');
    const splitNext = _.split(sortedTimes[i + 1], ' - ');

    if (splitIndex[1] === splitNext[0]) {
      sortedTimes[i] = `${splitIndex[0]} - ${splitNext[1]}`;
      sortedTimes.splice(i + 1, 1);
    } else {
      i += 1;
    }
  }
  return sortedTimes;
}

/**
 * Converts times in the given timezone into UTC array of { start, end }.
 */
function _createUTCBookings(selectedDate, times, timezone) {
  return times.map((time) => {
    const split = _.split(time, ' - ');
    const timestampStart = `${moment(selectedDate).format('YYYY-MM-DD')} ${split[0]}`;
    const momentStart = moment.tz(timestampStart, timezone.utc[0]);

    const utcMomentStart = momentStart.utc();

    const timestampEnd = `${moment(selectedDate).format('YYYY-MM-DD')} ${split[1]}`;
    const momentEnd = moment.tz(timestampEnd, timezone.utc[0]);
    const utcMomentEnd = momentEnd.clone().tz('Africa/Abidjan');

    return {
      // need to add the timezone because the default timezone for the server is -7
      // and therefore the db will try to convert to UTC even though we have already converted
      // here
      start: `${utcMomentStart.format('YYYY-MM-DD HH:mm:ss')}:00+00`,
      end: `${utcMomentEnd.format('YYYY-MM-DD HH:mm:ss')}:00+00`,
    };
  });
}

/**
 * Create a booking.
 * @param {String} panditId
 * @param {Object} bookingInfo - { selectedDate, selectedTimes, timezone }
 * @param {String} stripeToken
 * @param {Number} chargeAmount - amount to charge per booking slot
 * @returns {Promise} resolves with { status, bookings }
 */
export async function postBooking(panditId, bookingInfo, stripeToken, chargeAmount) {
  const {
    clientName,
    clientEmail,
    clientGoal,
    selectedDate,
    selectedTimes,
    timezone,
  } = bookingInfo;

  const mergedIntervals = _mergeOverlappingTimes(selectedTimes);

  const utcBookings = _createUTCBookings(selectedDate, mergedIntervals, timezone);

  const bookings = utcBookings.map((booking) => ({
    ...booking,
    panditId,
    clientName,
    clientEmail,
    clientGoal,
  }));

  const requestBody = {
    bookings,
    stripeToken,
    chargeAmount,
  };

  console.log(requestBody);

  return axios.post(`${BASE_URL}/bookings`, requestBody);
}

/**
 * Get the bookings associated with given query params.
 *
 * i.e. { panditId: 'somePanditId' }
 *
 * @param {Object} params
 */
export async function getBookings(params) {
  const response = await axios.get(`${BASE_URL}/bookings`, { params });
  // change to timezone object

  return _.map(response.data, (booking) => ({
    panditId: booking.panditId,
    studentId: booking.studentId,
    start: moment.tz(booking.start, utc.utc[0]),
    end: moment.tz(booking.end, utc.utc[0]),
  }));
}
