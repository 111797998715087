import React from 'react';
import {
  Redirect, BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';

import Navbar from 'components/Navbar';
import ProfileCard from 'pages/Admin/ProfileCard';
import User from 'pages/Admin/User';
import Skeleton from 'pages/Admin/Skeleton';
import { useAuth0 } from 'react-auth0-spa';
import styles from './Admin.module.scss';

export default function Admin({ match }) {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  if (!isAuthenticated) {
    return loginWithRedirect();
  }

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.leftSection}>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/profile`} />
        </Route>
        <Route path={`${match.path}/profile`} component={User} />
        <Route path={`${match.path}/bookings`} render={(props) => <Skeleton header="Bookings" />} />
        <Route path={`${match.path}/payments`} render={(props) => <Skeleton header="Payments" />} />
        <Route
          path={`${match.path}/analytics`}
          render={(props) => <Skeleton header="Analytics" />}
        />
      </div>
      <div className={styles.rightSection}>
        <ProfileCard />
      </div>
    </div>
  );
}
