import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Button from 'pages/Profile/Button';
import { withBookingContext } from 'pages/Profile/BookingContext';
import variables from 'sass/variables.scss';
import styles from './ConfirmationAndPayments.module.scss';

import './stripeElements.scss';

const cardElementOptions = {
  style: {
    base: {
      backgroundColor: variables.colorBackground,
      color: variables.colorBlack,
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '1.3',
    },
  },
};

const ConfirmationAndPayments = ({
  user,
  selectedDate,
  selectedTimes,
  timezone,
  nextStep,
  setStripeToken,
  createBooking,
}) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const continueDisabled = () => error;

  const processPayment = async () => {
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      await createBooking(result.token);
      nextStep();
    }
  };

  const parsedDate = moment(selectedDate).format('dddd, MMMM Do');

  const generateBookingSlots = () => selectedTimes.map((time) => {
    const timeSplit = _.split(time, ' - ');
    const startTime = moment(timeSplit[0], 'HH:mm:ss');

    const displayTime = `${startTime.format('hh:mm a')} - ${startTime
      .add(30, 'minutes')
      .format('hh:mm a')}`;

    return (
      <div className={styles.time} key={time}>
        {displayTime}
      </div>
    );
  });

  const rate = user.hourlyRate;
  const costWithoutFees = parseFloat(
    Math.round((rate / 2) * selectedTimes.length * 100) / 100,
  ).toFixed(2);
  const fee = parseFloat(Math.round(costWithoutFees * 0.15 * 100) / 100).toFixed(2);
  const total = parseFloat(Math.round(costWithoutFees * 1.15 * 100) / 100).toFixed(2);

  return (
    <div>
      <div className={styles.header}>Confirmation</div>
      <div className={styles.subheader}>{parsedDate}</div>
      {generateBookingSlots()}
      <div className={styles.subheader}>Due now</div>
      <div className={styles.dueNowRow}>
        <p>
          $
          {rate / 2}
          {' '}
          x
          {' '}
          {selectedTimes.length}
          {' '}
          blocks
        </p>
        <p>
          $
          {costWithoutFees}
        </p>
      </div>
      <div className={styles.dueNowRow}>
        <p>Service fee</p>
        <p>
          $
          {fee}
        </p>
      </div>
      <div className={styles.totalRow}>
        <b>Total</b>
        <b>
          $
          {total}
        </b>
      </div>
      <div className={styles.header}>Payment information</div>
      <div className={styles.elementContainer}>
        <CardElement options={cardElementOptions} />
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={async () => await processPayment()} disabled={continueDisabled()}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default withBookingContext(ConfirmationAndPayments);
