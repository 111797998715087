import React from 'react';
import moment from 'moment-timezone';

import { withBookingContext } from 'pages/Profile/BookingContext';
import styles from './TimeSelector.module.scss';

const TimeSelector = ({ selectedDate, setSelectedTimes, selectedTimes, getAvailabilityByDate }) => {
  const parsedDate = moment(selectedDate).format('dddd, MMMM Do');
  // TODO: can we just use weeklyAvailability here?
  const availability = getAvailabilityByDate(selectedDate);

  const onClickSelectTime = e => {
    const { value } = e.target;

    const selectedTimesClone = [...selectedTimes];

    const index = selectedTimesClone.indexOf(value);

    // if the time has already been select, remove from the array
    if (index === -1) {
      selectedTimesClone.push(value);
    } else {
      selectedTimesClone.splice(index, 1);
    }

    setSelectedTimes(selectedTimesClone);
  };

  const generateTimeList = () =>
    availability.map(x => {
      const start = x.start === '24:00:00' ? '00:00:00' : x.start;
      const end = x.end === '00:00:00' ? '24:00:00' : x.end;

      const startTime = moment(start, 'HH:mm:ss');
      const endTime = moment(end, 'HH:mm:ss');

      const buttons = [];

      // Check if the start time is before the end time (ignore the dates)
      while (startTime.isBefore(endTime)) {
        const start = startTime.clone();
        const end = startTime.add(30, 'minutes');

        const displayValue = `${start.format('hh:mm a')} - ${end.format('hh:mm a')}`;

        const value = `${start.format('HH:mm:ss')} - ${end.format('HH:mm:ss')}`;

        /* eslint-disable react/button-has-type */
        if (selectedTimes.includes(value)) {
          buttons.push(
            <button
              className={[styles.time, styles.selected].join(' ')}
              onClick={onClickSelectTime}
              value={value}
              key={value}
            >
              {displayValue}
            </button>
          );
        } else {
          buttons.push(
            <button className={styles.time} onClick={onClickSelectTime} value={value} key={value}>
              {displayValue}
            </button>
          );
        }
        /* eslint-enable react/button-has-type */
      }

      return buttons;
    });

  return (
    <>
      <div className={styles.header}>{parsedDate}</div>
      <div className={styles.timeList}>{generateTimeList()}</div>
    </>
  );
};

export default withBookingContext(TimeSelector);
