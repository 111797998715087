import React from 'react';

export const buildName = (firstName, lastName) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return '';
};

export const buildCompanyAndTitle = (title, company) => {
  if (!company) return title;
  return `${title}, ${company}`;
};

export const buildBio = (bio) => {
  const defaultBio = 'Hi! Welcome to my Profound profile. You can easily book me for a 1-on-1 conversation.\n\nTalk to me!';

  if (!bio) return createNewLine(defaultBio);

  return createNewLine(bio);
};

const createNewLine = (string) => string.split(/\n/gi).map((item, key) => (
  <span key={key}>
    {item}
    <br />
  </span>
));
