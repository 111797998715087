import React, { useState } from 'react';
import { Ring } from 'react-spinners-css';

import variables from 'sass/variables.scss';
import styles from './Input.module.scss';

export default function Input({ initialValue, label, type, updateValue, disabled, field, icon }) {
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onBlurWrapper = async () => {
    setLoading(true);

    const response = await updateValue({ [field]: value });

    if (!response.data) {
      if (response.err.status === 409) {
        setError(`${label} is already taken`);
      } else {
        setError('Unexpected error occured. Please try again.');
      }
    } else {
      setError(null);
    }

    setLoading(false);
  };

  return (
    <label>
      {label}
      {type === 'textarea' ? (
        <textarea
          className={[error && styles.error, styles.textarea].join(' ')}
          type={type}
          value={value}
          onChange={evt => setValue(evt.target.value)}
          onBlur={onBlurWrapper}
          disabled={disabled}
        />
      ) : (
        <input
          className={error && styles.error}
          type={type}
          value={value}
          onChange={evt =>
            field === 'username'
              ? setValue(evt.target.value.toLowerCase())
              : setValue(evt.target.value)
          }
          onBlur={onBlurWrapper}
          disabled={disabled}
          step="0.01"
          style={icon && { borderLeftWidth: '55.61px' }}
        />
      )}
      {icon && <img className={styles.icon} src={icon} />}
      {loading && <Ring className={styles.loading} color={variables.colorPurple} size={23} />}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </label>
  );
}
