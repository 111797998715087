import React from 'react';

import Icons from 'pages/Profile/About/Icons';
import { buildBio } from 'util/user';
import { withBookingContext } from 'pages/Profile/BookingContext';
import styles from './About.module.scss';

const About = ({ user }) => (
  <>
    <div className={styles.profileBio}>{buildBio(user.bio)}</div>
    <div className={styles.iconContainer}>
      <Icons pandit={user} />
    </div>
  </>
);

export default withBookingContext(About);
