import _ from 'lodash';

/**
 * Return the masked interval (pure function)
 *
 * @param {Array<Object>} interval interval of time
 * {
 *   start: 'HH:mm',
 *   end: 'HH:mm',
 * }
 *
 * @param {Array<Object>} mask intervals for the mask
 * {
 *   start: 'HH:mm',
 *   end: 'HH:mm',
 * }
 */
export function mergeIntervals(intervals) {
  let clonedIntervals = _.map(intervals, _.clone);
  clonedIntervals = _.sortBy(clonedIntervals, interval => {
    return interval.start;
  });

  const mergedIntervals = [];

  clonedIntervals.forEach(function(interval) {
    if (
      mergedIntervals.length === 0 ||
      mergedIntervals[mergedIntervals.length - 1].end < interval.start
    ) {
      mergedIntervals.push(interval);
    } else {
      mergedIntervals[mergedIntervals.length - 1].end =
        mergedIntervals[mergedIntervals.length - 1].end > interval.end
          ? mergedIntervals[mergedIntervals.length - 1].end
          : interval.end;
    }
  });

  return mergedIntervals;
}

export function maskIntervals(intervals, masks) {
  let clonedIntervals = mergeIntervals(intervals);
  let clonedMasks = mergeIntervals(masks);

  clonedIntervals = _.sortBy(clonedIntervals, interval => {
    return interval.start;
  });

  clonedMasks = _.sortBy(clonedMasks, mask => {
    return mask.start;
  });

  let intervalIndex = 0;
  let maskIndex = 0;

  const maskedIntervals = [];

  while (intervalIndex < clonedIntervals.length) {
    // if there are no more masks, then just add the rest of the intervals
    if (maskIndex >= clonedMasks.length) {
      maskedIntervals.push(clonedIntervals[intervalIndex]);

      intervalIndex++;
    } else if (clonedIntervals[intervalIndex].start < clonedMasks[maskIndex].start) {
      if (clonedIntervals[intervalIndex].end <= clonedMasks[maskIndex].start) {
        maskedIntervals.push(clonedIntervals[intervalIndex]);

        intervalIndex++;
      } else {
        maskedIntervals.push({
          start: clonedIntervals[intervalIndex].start,
          end: clonedMasks[maskIndex].start,
        });

        clonedIntervals[intervalIndex].start = clonedMasks[maskIndex].start;
      }
    } else if (clonedMasks[maskIndex].end <= clonedIntervals[intervalIndex].start) {
      maskIndex++;
    } else if (clonedMasks[maskIndex].end < clonedIntervals[intervalIndex].end) {
      clonedIntervals[intervalIndex].start = clonedMasks[maskIndex].end;

      maskIndex++;
    } else {
      intervalIndex++;
    }
  }

  return maskedIntervals;
}

export function mergeIntervalsByDay(weeklyAvailability) {
  const weeklyAvailabilitiesByDay = weeklyAvailability.reduce((acc, cur) => {
    if (cur.day in acc) {
      acc[cur.day].push(cur);
    } else {
      acc[cur.day] = [cur];
    }
    return acc;
  }, {});
  const mergedIntervalsByDay = Object.keys(weeklyAvailabilitiesByDay).map(day =>
    mergeIntervals(weeklyAvailabilitiesByDay[day])
  );
  return mergedIntervalsByDay.reduce(
    (allAvailabilities, availabilitiesForDay) => allAvailabilities.concat(availabilitiesForDay),
    []
  );
}
