import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';

import timezones from '../assets/timezones.json';

const dayToDate = [
  '2019-06-02',
  '2019-06-03',
  '2019-06-04',
  '2019-06-05',
  '2019-06-06',
  '2019-06-07',
  '2019-06-08',
];

const gmt = timezones.find(timezone => timezone.abbr === 'GMT');

/**
 * Change the timezone for weeklyAvailability from one timezone to another
 *
 * @param {Object[]} weeklyAvailability array of moment objects representing weekly availability
 * @param {Object[]} toTimezone timezone object that the weekly availability to change to
 *
 * @return {Object[]} adjusted weekly availability
 */
export function changeTimezone(weeklyAvailability = [], toTimezone = gmt) {
  const toWeeklyAvailability = [];

  weeklyAvailability.forEach(availability => {
    const toStart = availability.start.clone().tz(toTimezone.utc[0]);
    const toEnd = availability.end.clone().tz(toTimezone.utc[0]);

    const isEndOfDayInterval = () => {
      // check if the interval is for the end of the day 11:30pm - 12:00am.
      // if so, they should not be considered as split between two dates
      return (
        (toEnd.day() - toStart.day() === 1 || (toStart.day() === 6 && toEnd.day() === 0)) &&
        toEnd.format('HH:mm') === '00:00'
      );
    };

    if (toStart.day() !== toEnd.day() && !isEndOfDayInterval()) {
      // Due to the shift in the timezones now the time block is between two
      // dates, therefore we need to create two availability objects
      const toAvailabilityFirstDay = {
        day: toStart.day(),
        start: toStart,
        end: moment.tz(`${toStart.format('YYYY-MM-DD')} 24:00`, toTimezone.utc[0]),
      };

      const toAvailabilitySecondDay = {
        day: toEnd.day(),
        start: moment.tz(`${toEnd.format('YYYY-MM-DD')} 00:00`, toTimezone.utc[0]),
        end: toEnd,
      };

      toWeeklyAvailability.push(toAvailabilityFirstDay);
      toWeeklyAvailability.push(toAvailabilitySecondDay);
    } else {
      // Changing the timezone does not split it across different days
      const toAvailability = {
        day: toStart.day(),
        start: toStart,
        end: toEnd,
      };

      toWeeklyAvailability.push(toAvailability);
    }
  });

  return toWeeklyAvailability;
}

/**
 * Change day and time to moment objects with the anchor dates
 *
 * @param {Integer} day number representation of the day of the week i.e. 1 for Monday
 * @param {String} time HH:mm:ss representation of the time
 * @param {Object} timezone
 */
export function toMoment(day, time, timezone, options = {}) {
  if (options.forceCorrectDay && options.end && time === '00:00:00') {
    // you have to set the day ahead only when the time is midnight when it's at
    // the end of the interval. this is because of how the day is being inputted into
    // the db. if a WeeklyAvailability (day, start, end) tuple is (2, '15:00:00', '00:00:00')
    // then you only want to increment the day if we are converting the end time.
    day = (day + 1) % 7;
  }
  return moment.tz(`${dayToDate[day]} ${time}`, timezone.utc[0]);
}

/**
 * Transform from a Moment Object to TIME type for Postgres
 *
 * @param {Object} weeklyAvailability
 */
export function toString(weeklyAvailability) {
  return weeklyAvailability.map(avail => ({
    day: avail.day,
    start: avail.start.format('HH:mm:ss'),
    end: avail.end.format('HH:mm:ss'),
  }));
}

/**
 * Return the default timezone of the user
 *
 * @return {Object} timezone Object from timezones.json
 */
export function getDefaultTimezone() {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  Sentry.addBreadcrumb({
    category: 'timezone.js',
    data: { browserTimezone },
    level: Sentry.Severity.Info,
  });

  return timezones.find(timezone => timezone.utc.includes(browserTimezone)) || timezones[0];
}
