import React from 'react';

import Button from 'pages/Profile/Button';
import Icons from 'pages/Profile/About/Icons';
import { buildCompanyAndTitle, buildBio } from 'util/user';
import { useAuth0 } from 'react-auth0-spa.js';
import banner from 'assets/banner.png';
import external from 'assets/external.svg';
import poweredByProfound from 'assets/poweredByProfound.svg';

import styles from './ProfileCard.module.scss';

export default function ProfileCard() {
  const { user } = useAuth0();

  // TODO: Add banner images into the backend and allow users to change images
  const bannerStyles = {
    background: `url(${banner}) no-repeat center center / cover`,
  };

  const { company, title, hourlyRate, profilePicture, username, displayName } = user;

  return (
    <>
      <div className={styles.header}>My Profound Link</div>
      {username ? (
        <a href={`https://pfd.chat/u/${username}`} target="_blank">
          <div className={styles.linkContainer}>
            {`https://pfd.chat/u/${username}`}
            <img src={external} />
          </div>
        </a>
      ) : (
        'Choose a username for your Profound link!'
      )}
      <div className={styles.card}>
        <div className={styles.banner} style={bannerStyles} />
        <img className={styles.poweredByProfound} src={poweredByProfound} />
        <div className={styles.profilePhotoContainer}>
          <img className={styles.profilePhoto} src={profilePicture} />
        </div>
        <div className={styles.nameAndButtonContainer}>
          <div className={styles.profileNameAndTitleContainer}>
            <h1>{displayName}</h1>
            <div className={styles.highlightContainer}>
              <h2>{buildCompanyAndTitle(title, company)}</h2>
              <div className={styles.highlightYellow} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button maxWidth>
              <div className={styles.buttonContentContainer}>
                <p>
                  {' '}
                  Talk to me for <strong>${hourlyRate / 2}</strong>
                </p>
              </div>
            </Button>
          </div>
        </div>
        <div className={styles.seperator} />
        <div className={styles.profileBio}>{buildBio(user.bio)}</div>
        <div className={styles.iconContainer}>
          <Icons pandit={user} />
        </div>
      </div>
    </>
  );
}
