import Calendar from 'react-calendar/dist/entry.nostyle';
import React, { useState } from 'react';
import moment from 'moment';

import { withBookingContext } from 'pages/Profile/BookingContext';
import Button from 'pages/Profile/Button';
import TimeSelector from './TimeSelector';
import TimezoneDropdown from './TimezoneDropdown';
import styles from './SelectTimeAndDate.module.scss';
import './react-calendar.css';

const kelseyBlackoutDates = [
  new Date('06/05/2020'),
  new Date('06/11/2020'),
  new Date('06/18/2020'),
  new Date('06/23/2020'),
  new Date('06/24/2020'),
  new Date('06/25/2020'),
  new Date('06/26/2020'),
];

const SelectTimeAndDate = ({
  selectedDate,
  selectedTimes,
  setSelectedDate,
  getAvailabilityByDate,
  nextStep,
  user,
}) => {
  const tileDisabled = ({ date }) => {
    // TODO: remove hardcoded techdebt

    if (user.username === 'kelsey') {
      const found = kelseyBlackoutDates.find(blackoutDate =>
        moment(blackoutDate).isSame(date, 'day')
      );
      if (found) return true;
    }

    if (date <= new Date()) return true;

    const avail = getAvailabilityByDate(date);
    return avail.length === 0;
  };

  const continueDisabled = () => selectedTimes.length === 0 || !selectedDate;

  return (
    <>
      <div className={styles.header}>Select a date and time</div>
      <Calendar
        className={styles.calendar}
        tileDisabled={tileDisabled}
        minDetail="month"
        onClickDay={setSelectedDate}
      />
      <div className={styles.timezoneContainer}>
        <TimezoneDropdown />
      </div>
      {selectedDate && (
        <>
          <div className={styles.seperator} />
          <TimeSelector />
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button onClick={() => nextStep()} disabled={continueDisabled()}>
          Continue
        </Button>
      </div>
    </>
  );
};

export default withBookingContext(SelectTimeAndDate);
