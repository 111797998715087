import React, { useState } from 'react';

import Button from 'pages/Profile/Button';
import { withBookingContext } from 'pages/Profile/BookingContext';
import styles from './BookingDetails.module.scss';

const BookingDetails = ({
  clientName,
  setClientName,
  clientEmail,
  setClientEmail,
  clientGoal,
  setClientGoal,
  nextStep,
}) => {
  const changeName = (evt) => {
    setClientName(evt.target.value);
  };

  const changeEmail = (evt) => {
    setClientEmail(evt.target.value);
  };

  const changeGoals = (evt) => {
    setClientGoal(evt.target.value);
  };

  const continueDisabled = () => clientName.length === 0 || clientEmail.length === 0 || clientGoal.length === 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>Booking details</div>
      <form className={styles.detailsForm}>
        <label>
          Name
          <input type="text" value={clientName} onChange={changeName} />
        </label>
        <label>
          Email address
          <input type="text" value={clientEmail} onChange={changeEmail} />
        </label>
        <label>
          What is your one goal during our time together?
          <textarea type="text" value={clientGoal} onChange={changeGoals} />
        </label>
      </form>
      <div className={styles.buttonContainer}>
        <Button onClick={() => nextStep()} disabled={continueDisabled()}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default withBookingContext(BookingDetails);
