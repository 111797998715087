import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useAuth0 } from 'react-auth0-spa';
import { getWeeklyAvailability, updateWeeklyAvailability } from 'services/weeklyAvailability';
import { toMoment, getDefaultTimezone } from 'util/timezone';

import AvailableTimesOnDay from './AvailableTimesOnDay';
import Day from './Day';
import { days, chosenDaysSorter } from './utils';
import styles from './WeeklyAvailabilitiesInput.module.scss';

function formatCurrentlyChosenDaysToRead(availabilities) {
  const currentlyChosenDaysNumbers = availabilities.map(({ day }) => day);
  let currentlyChosenDays = currentlyChosenDaysNumbers.map(dayNumber => {
    const { day } = days.find(({ number }) => number === dayNumber);
    return day;
  });
  currentlyChosenDays = [...new Set(currentlyChosenDays)];
  return currentlyChosenDays.sort(chosenDaysSorter);
}

function dayAvailabilitySorter(day1, day2) {
  // eslint-disable-next-line no-nested-ternary
  return day1.start < day2.start ? -1 : day1.start > day2.start ? 1 : 0;
}

function formatChosenAvailabilitiesToRead(availabilities) {
  const formattedChosenAvailabilities = availabilities.map(({ start, end, day }) => {
    const { day: dayName } = days.find(d => d.number === day);

    const formatTime = time => {
      return time.format('hh:mm a');
    };

    return {
      day: dayName,
      start: formatTime(start),
      end: formatTime(end),
    };
  });
  const formattedChosenAvailabilitiesKeyedByDay = formattedChosenAvailabilities.reduce(
    (acc, cur) => {
      if (cur.day in acc) {
        acc[cur.day].push(cur);
      } else {
        acc[cur.day] = [cur];
      }
      return acc;
    },
    {}
  );

  // sort the intervals within a day
  Object.keys(formattedChosenAvailabilitiesKeyedByDay).map(key =>
    formattedChosenAvailabilitiesKeyedByDay[key].sort(dayAvailabilitySorter)
  );

  return formattedChosenAvailabilitiesKeyedByDay;
}

export default function WeeklyAvailabilitiesInput() {
  const { user, getTokenSilently } = useAuth0();

  const [chosenDays, setChosenDays] = useState([]);
  const [chosenAvailabilities, setChosenAvailabilities] = useState([]);

  const timezone = getDefaultTimezone();

  useEffect(() => {
    async function fetchAvailabilites() {
      const availabilites = await getWeeklyAvailability(user.id, { timezone });

      const currentlyChosenDays = formatCurrentlyChosenDaysToRead(availabilites);
      setChosenDays(currentlyChosenDays);

      const formattedChosenAvailabilities = formatChosenAvailabilitiesToRead(availabilites);
      setChosenAvailabilities(formattedChosenAvailabilities);
    }

    fetchAvailabilites();
  }, []);

  const submitWeeklyAvailabilities = async (availabilities = chosenAvailabilities) => {
    let formattedChosenAvailabilities = Object.keys(availabilities).reduce(
      (allAvailabilities, day) => allAvailabilities.concat(availabilities[day]),
      []
    );

    formattedChosenAvailabilities = formattedChosenAvailabilities.filter(availability =>
      Object.keys(availability).every(
        k => availability[k] !== null && availability[k] !== undefined
      )
    );
    formattedChosenAvailabilities = formattedChosenAvailabilities.map(({ start, end, day }) => {
      const { number: dayNumber } = days.find(d => d.day === day);

      const formatTime = (time, options = {}) => {
        const formattedTime = moment(time, ['hh:mm a']).format('HH:mm:ss');
        return toMoment(dayNumber, formattedTime, timezone, {
          forceCorrectDay: true,
          end: options.end,
        });
      };

      return {
        day: dayNumber,
        start: formatTime(start),
        end: formatTime(end, { end: true }),
      };
    });

    const token = await getTokenSilently();
    await updateWeeklyAvailability(user.id, formattedChosenAvailabilities, timezone, token);
  };

  const addAvailability = (day, start, end) => {
    const currentAvailabilitiesForDay = chosenAvailabilities[day];

    let newAvailabilitiesForDay;
    if (currentAvailabilitiesForDay) {
      newAvailabilitiesForDay = [...currentAvailabilitiesForDay, { start, end, day }];
    } else {
      newAvailabilitiesForDay = [{ start, end, day }];
    }

    setChosenAvailabilities({
      ...chosenAvailabilities,
      [day]: newAvailabilitiesForDay,
    });
  };

  const updateAvailability = async (day, idx, { start, end }) => {
    const oldAvailabilitiesForDay = chosenAvailabilities[day];
    if (!oldAvailabilitiesForDay || !oldAvailabilitiesForDay.length) return;

    const availabilityForDayToUpdate = { ...oldAvailabilitiesForDay[idx] };
    if (start || start === null) {
      availabilityForDayToUpdate.start = start;
    }
    if (end || end === null) {
      availabilityForDayToUpdate.end = end;
    }

    const newAvailabilitiesForDay = [
      ...oldAvailabilitiesForDay.slice(0, idx),
      availabilityForDayToUpdate,
      ...oldAvailabilitiesForDay.slice(idx + 1),
    ];

    const newChosenAvailabilities = {
      ...chosenAvailabilities,
      [day]: newAvailabilitiesForDay,
    };
    setChosenAvailabilities(newChosenAvailabilities);

    // we do this to make sure we have access to the latest state
    await submitWeeklyAvailabilities(newChosenAvailabilities);
  };

  const removeAllAvailabilitiesForDay = async day => {
    const newChosenAvailabilities = {
      ...chosenAvailabilities,
      [day]: [],
    };
    setChosenAvailabilities(newChosenAvailabilities);

    await submitWeeklyAvailabilities(newChosenAvailabilities);
  };

  const removeAvailability = async (day, idx) => {
    const oldAvailabilitiesForDay = chosenAvailabilities[day];

    const newAvailabilitiesForDay = [...oldAvailabilitiesForDay];
    newAvailabilitiesForDay.splice(idx, 1);

    const newChosenAvailabilities = {
      ...chosenAvailabilities,
      [day]: newAvailabilitiesForDay,
    };

    if (!newAvailabilitiesForDay.length) {
      const newChosenDays = chosenDays.filter(d => d !== day);
      setChosenDays(newChosenDays);
    }

    setChosenAvailabilities(newChosenAvailabilities);

    // we do this to make sure we have access to the latest state
    await submitWeeklyAvailabilities(newChosenAvailabilities);
  };

  const onDayClick = async e => {
    const day = e.target.value;

    const newChosenDays = [...chosenDays];
    const idx = newChosenDays.indexOf(day);
    if (idx !== -1) {
      newChosenDays.splice(idx, 1);
      await removeAllAvailabilitiesForDay(day);
    } else {
      newChosenDays.push(day);
      addAvailability(day, null, null);
    }

    setChosenDays(newChosenDays.sort(chosenDaysSorter));
  };

  const Days = (
    <div className={styles.dayBadges}>
      {days.map(day => (
        <Day key={day.day} day={day} chosenDays={chosenDays} onDayClick={onDayClick} />
      ))}
    </div>
  );

  const AvailableTimesOnDays = (
    <div>
      {chosenDays.map(day => {
        const availability = chosenAvailabilities[day];
        return availability ? (
          <AvailableTimesOnDay
            key={day}
            day={day}
            availability={availability}
            addAvailability={addAvailability}
            removeAvailability={removeAvailability}
            updateAvailability={updateAvailability}
            submitWeeklyAvailabilities={submitWeeklyAvailabilities}
          />
        ) : null;
      })}
    </div>
  );

  const handleBlur = e => {
    const { currentTarget } = e;

    setTimeout(async () => {
      if (!currentTarget.contains(document.activeElement)) {
        await submitWeeklyAvailabilities();
      }
    }, 0);
  };

  return (
    <div tabIndex="1" onBlur={handleBlur} className={styles.weeklyAvailability}>
      {Days}
      {AvailableTimesOnDays}
    </div>
  );
}
