import axios from 'axios';

import { mergeIntervalsByDay } from 'util/intervals';

import timezones from '../assets/timezones.json';
import { changeTimezone, toMoment, toString } from '../util/timezone';

const BASE_URL = process.env.REACT_APP_BACKEND_ROUTE;

const utc = timezones.find(timezone => timezone.offset === 0);

/**
 * Change the timezone to UTC and update weekly availability for a given user.
 *
 * @param {String} userId
 * @param {Array<Object>} weeklyAvailability - [{start: moment, end: moment, day: integer}]
 * @param {Object} timezone timezone of the weekly availability
 */
export async function updateWeeklyAvailability(userId, weeklyAvailability, timezone, idToken) {
  weeklyAvailability.forEach(avail => {
    avail.start.tz(timezone.utc[0]);
    avail.end.tz(timezone.utc[0]);
  });

  const utcWeeklyAvailability = changeTimezone(weeklyAvailability, utc);

  const formattedWeeklyAvailabilty = toString(utcWeeklyAvailability);

  try {
    const response = await axios.post(
      `${BASE_URL}/users/${userId}/weekly_availability`,
      { weeklyAvailability: formattedWeeklyAvailabilty },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (response.status >= 400) {
      console.error(response.data, 'Error while updating weekly availability');
      // TODO: appropriately handle error
      return null;
    }
  } catch (err) {
    console.error(err, 'Error while updating weekly availability');
    // TODO: appropriately handle error
    return null;
  }
}

/**
 * Get a user's weekly availability.
 * @param {String} userId
 *
 * @return {Object[]} an array of moment objects in UTC
 */
export async function getWeeklyAvailability(userId, options = {}) {
  let weeklyAvailability = [];
  if (!userId) return weeklyAvailability;

  const availabilityResponse = await axios.get(`${BASE_URL}/users/${userId}/weekly_availability`);

  availabilityResponse.data.forEach(availability => {
    const momentAvailability = {
      day: availability.day,
      start: toMoment(availability.day, availability.start, utc, { forceCorrectDay: true }),
      end: toMoment(availability.day, availability.end, utc, { forceCorrectDay: true, end: true }),
    };

    weeklyAvailability.push(momentAvailability);
  });

  if (options.timezone) {
    weeklyAvailability = changeTimezone(weeklyAvailability, options.timezone);
    weeklyAvailability = mergeIntervalsByDay(weeklyAvailability);
  }

  return weeklyAvailability;
}
